import Vue from 'vue'
import Router from "vue-router";
// import VueMeta from 'vue-meta';
import store from './store';

Vue.use(Router)
// Vue.use(VueMeta)



const router = new Router({
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    to
    from 
    savedPosition
    return new Promise((resolve, reject) => {
      reject
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 300)
    })
  },
  
    routes: [
      {
        path: '/',
        // name:'alkafeel',
      
        component: () => import('@/views/user/Index'),

        children: [
          {
            name: 'Home',
            path: '',
            title:'Home',
            component: () => import(/*webpackChunkName:"home" */'@/views/user/home.vue')
          },
      {
        path: '/news',
        name:'news',
        component:() => import(/*webpackChunkName:"search" */'./views/user/news.vue'),
      },
      {
        path: '/photos',
        name:'photos',
        component:() => import(/*webpackChunkName:"search" */'./views/user/photos.vue'),
      },
      {
        path: '/news/:id',
        name:'new',
        component:() => import(/*webpackChunkName:"search" */'./views/user/news_inner.vue'),
      },
      // {
      //   path: '/callUs',
      //   name:'callUs',
      //   component:() => import(/*webpackChunkName:"search" */'./views/user/callUsHome.vue'),
      // },
      {
        path: '/library',
        name:'library',
        component:() => import(/*webpackChunkName:"search" */'./views/user/library.vue'),
      },
      {
        path: '/library/:id',
        name:'book',
        component:() => import(/*webpackChunkName:"search" */'./views/user/book.vue'),
      },
      {
        path: '/video',
        name:'videos',
        component:() => import(/*webpackChunkName:"search" */'./views/user/video.vue'),
      },
      {
        path: '/video/:id',
        name:'video',
        component:() => import(/*webpackChunkName:"search" */'./views/user/video_inner.vue'),
      },
      {
        path: '/quran/:cat',
        name:'quran',
        component:() => import(/*webpackChunkName:"search" */'./views/user/quran.vue'),
      },


      
      ]
      },

      {
        path: '/auth',
        name:'auth',
        component: () => import('./views/pages/Index.vue'),
        children: [{
          name: 'Login',
          path: '',
          component: () => import('./views/pages/Login.vue')
        }]
      },
      {
        path: '/admin',
        component: () => import('./views/dashboard/Index.vue'),
        beforeEnter: (to, from, next) => {
          
           if (localStorage.getItem('tokinn') && store.state.AdminInfo.authe==true) {
            next()
          } else {
            next({
              name: 'Login'
            });
          }
        },   
        children: [
          // {
          //   name: 'statistics',
          //   path: 'statistics',
          //   component: () => import('./views/dashboard/Dashboard.vue')
          // },

            {
            name: 'articles',
            path: 'articles',
            component: () => import('./views/dashboard/articles.vue')
          },


          {
            path: 'about',
            name:'about',
            component: () => import('./views/dashboard/about.vue'),
            
          },

          {
            path: 'qurans',
            name:'Qurans',
            component: () => import('./views/dashboard/qurans.vue'),
            
          },
          
          {
            path: 'Gallery',
            name:'Gallery',
            component: () => import('./views/dashboard/Gallery.vue'),
            
          },

          {
            path: 'GalleryCats',
            name:'GalleryCats',
            component: () => import('./views/dashboard/GalleryCats.vue'),
            
          },
          //GalleryCats.vue

          {
            path: 'articles',
            name:'articles',
            component: () => import('./views/dashboard/articles.vue'),
            
          },
          {
            path: 'messages',
            name:'messages',
            component: () => import('./views/dashboard/messages.vue'),
            
          },

          {
            path: 'artCats',
            name:'artCats',
            component: () => import('./views/dashboard/artCats.vue'),
            
          },

          {
            path: 'books',
            name:'books',
            component: () => import('./views/dashboard/books.vue'),

          },

          {
            path: 'videos',
            name:'videos',
            component: () => import('./views/dashboard/videos.vue'),

          },
          {
            path:'libraryImage',
            name:'libraryImage',
            component: () => import('./views/dashboard/libraryImage.vue'),
          },
          {
            path:'callUs',
            name:'callUs',
            component: () => import('./views/dashboard/callUs.vue'),
          }


       
        ]   
        
      },
    
    
    
       
       
    ]
    });
    
    
    
    
    router.beforeEach((to, from, next) => {
     
        window.document.title = to.name ;
   
      next();
    });
    
    export default router;

